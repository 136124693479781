import { atom, getDefaultStore } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import type { WorkspaceTypeLite } from "./http/api/workspaces/types";

const defaultStore = getDefaultStore();

const sideMenuStorage = createJSONStorage<boolean>(() => sessionStorage);
export const sideMenuAtom = atomWithStorage<boolean>(
  "showSideMenu",
  true,
  sideMenuStorage,
);

export const userAtom = atom<Partial<UserAtomType>>({});

export const isCompanyAdminAtom = atom(
  (get) => get(userAtom).isAdmin ?? false,
  (get, set, isAdmin: boolean) => {
    const user = get(userAtom);
    set(userAtom, { ...user, isAdmin });
  },
);

export const setIsCompanyAdmin = (isAdmin: boolean) => {
  defaultStore.set(isCompanyAdminAtom, isAdmin);
};

export const userWorkspacesAtom = atom<WorkspaceTypeLite[]>([]);
export const setUserWorkspaces = (userWorkspaces: WorkspaceTypeLite[]) => {
  defaultStore.set(userWorkspacesAtom, userWorkspaces);
};

export interface UserAtomType {
  id: string;
  email: string;
  company_id: string;
  isAdmin: boolean;
}
