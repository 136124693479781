import HttpClient from "@/http/httpClient";
import type {
  AreaBumpChartData,
  GetInventoryResponseType,
  GetRegulatoryInventoryData,
  GetRegulatorsResponseType,
  GetUpdatesResponseType,
} from "./types";

export async function getRegulators({ signal }: { signal?: AbortSignal }) {
  return await HttpClient.request().get<GetRegulatorsResponseType>(
    "regulatory-updates/regulators",
    { signal },
  );
}

export async function getRegulatoryInventoryData(
  request: GetRegulatoryInventoryData,
) {
  return HttpClient.request().get<GetInventoryResponseType>(
    `regulatory-updates/${request.regulator}/inventory`,
  );
}

export async function getRecentRegulatoryUpdates({
  signal,
}: {
  signal?: AbortSignal;
}) {
  return await HttpClient.request().get<GetUpdatesResponseType>(
    "regulatory-updates/recent-updates",
    { signal },
  );
}

export async function getRegulatoryHubNews({
  signal,
}: {
  signal?: AbortSignal;
}) {
  return await HttpClient.request().get<GetUpdatesResponseType>(
    "regulatory-updates/news",
    { signal },
  );
}

export async function getFunnelChartData() {
  return await HttpClient.request().get<AreaBumpChartData>(
    "regulatory-updates/statistics-by-regulator",
  );
}
