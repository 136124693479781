import { hasAccessToRegulatoryHub, throwErrorResponse } from "@/helpers";
import {
  getRecentRegulatoryUpdates,
  getRegulators,
  getRegulatoryHubNews,
} from "@/http/api/regulatory-updates";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function regulatoryHubLoader({
  request,
}: LoaderFunctionArgs) {
  if (!hasAccessToRegulatoryHub()) {
    return throwErrorResponse({
      status: 404,
      statusText: "Page not found",
    });
  }

  const { regulators } = await getRegulators({ signal: request.signal });

  const newsData = await getRegulatoryHubNews({
    signal: request.signal,
  });
  const updatesData = await getRecentRegulatoryUpdates({
    signal: request.signal,
  });

  return {
    regulators,
    news: newsData.regulations,
    // TODO: ask BE to return only te latest 3 items
    regulatoryUpdates: updatesData.regulations.slice(0, 3),
  };
}
