import { HTTPError } from "ky";
import { isToday, isYesterday, lightFormat } from "date-fns";
import {
  DB_COMPANY_ID,
  MARKET_NEWSFLASH_TEMPLATE_SLUG,
  REGULATORY_UPDATES_TEMPLATE_SLUG,
} from "./constants";
import type { DocumentType } from "@/http/api/document/types";
import type { Template } from "./http/api/templates/types";
import type { postSourcePDFType } from "./http/api/sources/types";
import { getDefaultStore } from "jotai";
import { userAtom } from "./atoms";

const defaultStore = getDefaultStore();

export const getWorkspacePath = (workspaceId: string) => {
  return `/app/workspaces/${workspaceId}`;
};

export const getInitialWorkspaceId = (
  workspaces: { id: string; is_personal: boolean }[],
) => {
  const personalWorkspace = workspaces.find(
    (workspace) => workspace.is_personal,
  );
  if (personalWorkspace) {
    return personalWorkspace.id;
  }
  return workspaces[0]?.id ?? "";
};

export const throwErrorResponse = ({
  status,
  statusText,
}: {
  status: number;
  statusText: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/only-throw-error
  throw {
    status,
    statusText,
    data: "",
    internal: true,
  };
};

export const getHTTPErrorMessage = async (
  error: unknown,
  errorMessageHandler?: errorMessageHandlerType,
) => {
  let errorMessage = "An unexpected error occurred. Please try again.";
  if (typeof errorMessageHandler === "string") {
    errorMessage = errorMessageHandler;
  }
  if (error instanceof HTTPError) {
    const customErrorMessage = errorMessageHandler?.[error.response.status];
    if (customErrorMessage) {
      errorMessage = customErrorMessage;
    } else if (error.response.status === 500) {
      errorMessage =
        "We're having technical difficulties, please try again later.";
    } else {
      try {
        const errorResponse = await error.response.json<{
          detail: string;
        }>();
        errorMessage = errorResponse.detail;
      } catch (e: unknown) {
        // ignore
        console.log(e);
      }
    }
  } else if (typeof error === "string") {
    errorMessage = error;
  }
  return errorMessage;
};

type errorMessageHandlerType = string | Record<number, string>;

export function replaceLastPathWith(replacement: string) {
  return window.location.pathname.replace(/\/[^/]+$/, `/${replacement}`);
}

export function parseDate(date: string, isEuropeanFormat?: boolean) {
  const parsedDate = new Date(date);
  if (isToday(parsedDate)) return "Today";
  if (isYesterday(parsedDate)) return "Yesterday";
  return lightFormat(
    parsedDate,
    isEuropeanFormat ? "dd.MM.yyyy" : "MM/dd/yyyy",
  );
}

export const getFilteredData = ({
  data,
  route,
}: {
  data: DocumentType[];
  route: "documents" | "reports";
}) => {
  const reportTemplateSlugs = [
    MARKET_NEWSFLASH_TEMPLATE_SLUG,
    REGULATORY_UPDATES_TEMPLATE_SLUG,
  ];

  const filteredData = data.filter((doc) => {
    if (route === "documents") {
      // Filter out reports from the data
      return !reportTemplateSlugs.includes(doc.template_slug);
    }
    // Filter out non-reports from the data
    return reportTemplateSlugs.includes(doc.template_slug);
  });
  return filteredData;
};

export const hasUserAccessToNewsflash = (templates: Template[]) => {
  return templates.some(
    (template: Template) => template.slug === MARKET_NEWSFLASH_TEMPLATE_SLUG,
  );
};

export function getSourceKey(source: postSourcePDFType | null) {
  if (!source) return "";
  return `${String(source.file_hash)}-${String(source.page_no)}`;
}

export function downloadBlob({
  blob,
  type,
  fileName,
}: {
  blob: Blob;
  type: "docx" | "pdf";
  fileName: string;
}) {
  const mimeType =
    type === "docx"
      ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      : "application/pdf";
  const file = window.URL.createObjectURL(new Blob([blob], { type: mimeType }));

  const link = window.document.createElement("a");
  link.href = file;
  link.download = fileName;
  link.style.display = "none";

  window.document.body.append(link);
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(file);
    link.remove();
  }, 1000);
}

export function hasAccessToRegulatoryHub() {
  // Hide the regulatory compliance section for now for DB users
  const user = defaultStore.get(userAtom);
  const { company_id } = user;
  return company_id !== DB_COMPANY_ID;
}
