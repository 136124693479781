import { getMe } from "@/http/api/users";
import { setIsCompanyAdmin, setUserWorkspaces, userAtom } from "@/atoms";
import type { LoaderFunctionArgs } from "react-router-dom";
import { getDefaultStore } from "jotai";
import AuthClient from "@/http/authClient";

const defaultStore = getDefaultStore();

export default async function appLoader({ request }: LoaderFunctionArgs) {
  const requestURL = new URL(request.url);
  if (requestURL.pathname.includes("/admin")) {
    return null;
  }

  const authToken = AuthClient.getToken();

  if (authToken) {
    const decodedToken = AuthClient.getDecodedToken(authToken);
    const { user_id, company_id, email, is_admin } = decodedToken;
    defaultStore.set(userAtom, {
      id: user_id,
      email,
      company_id: company_id,
      isAdmin: is_admin,
    });
  } else {
    await AuthClient.refreshToken();
  }

  const me = await getMe({ signal: request.signal });
  setIsCompanyAdmin(me.is_company_admin);
  const sortedWorkspaces = me.workspace_memberships.sort((a, b) =>
    a.created_at.localeCompare(b.created_at),
  );
  setUserWorkspaces(sortedWorkspaces);
  return sortedWorkspaces;
}
